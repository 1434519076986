
/*! ===========================================================================
 *   Some jQuery.UI functions conflict with Bootstrap. This code renames those
 *   Bootstrap functions so they can still be used.
 *   NOTE: This file must be loaded after Bootstrap.js and before jQuery.UI
 *  =========================================================================== */

// Bootstrap's tooltip conflicts with jQuery.UI's tooltip
var bsTooltip = $.fn.tooltip.noConflict();
$.fn.bootstrapTooltip = bsTooltip;     		// Use bootstrapTooltip() instead of tooltip()

// Bootstrap's button conflicts with jQuery.UI's button
var bsButton = $.fn.button.noConflict();
$.fn.bootstrapButton = bsButton;     		// Use bootstrapButton() instead of button()


/*! ===========================================================================
 *   Global event for Bootstrap Popovers that are opened/closed manually:          
 *   Click event so users can click anywhere on screen or [X] to close popover.  
 *  =========================================================================== */

$(document).click(function (e) {
    if ($(e.target).is(".cmg_popover_close")) {
        $(".popover").popover("hide");
        e.preventDefault();
    }
    else if (($(".popover").has(e.target).length === 0)) {
        $(".popover").popover("hide");
    }
});
